import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";
import * as _ from "lodash";
var querystring = require("querystring");

export class Unit {
  static async refreshUnit(id) {
    const url = `${ServerSettings.baseUrl}/units/${id}/stats/basic/refresh`;
    const response = await xhr.post("", url);

    return response.data;
  }

  static async fetch(id) {
    const url = `${ServerSettings.baseUrl}/units/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/units/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }
  static async updateUnits(data) {
    const url = `${ServerSettings.baseUrl}/units/`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async updateUnitsAndSchedules(data) {
    const url = `${ServerSettings.baseUrl}/units/withSchedules`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async setEWrcLock(unitId, data) {
    const url = `${ServerSettings.baseUrl}/units/${unitId}/tStat`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async setManyEWrcLocks(data) {
    const url = `${ServerSettings.baseUrl}/units/tStat/many`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async getEWrcLock(unitId) {
    const url = `${ServerSettings.baseUrl}/units/${unitId}/tStat`;
    const response = await xhr.get(url);

    return response.data;
  }


  static async toggleEWrcLocks(data) {
    const url = `${ServerSettings.baseUrl}/units/tStat/toggleTStat`;
    const response = await xhr.put(data, url);

    return response.data;
  }



  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/units/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async setActiveSetpoint(id, setpoint: number) {
    const url = `${ServerSettings.baseUrl}/units/${id}/controls/setpoints`;
    const response = await xhr.put({ setpoint: setpoint }, url);

    return response.data;
  }

  static async setActiveOperationMode(id, mode: number) {
    const url = `${ServerSettings.baseUrl}/units/${id}/controls/modes`;
    const response = await xhr.put({ operationMode: mode }, url);

    return response.data;
  }

  static async setActiveFanMode(id, fanMode) {
    const url = `${ServerSettings.baseUrl}/units/${id}/controls/fans`;
    const response = await xhr.put({ fanMode }, url);

    return response.data;
  }

  static async clearFilter(id) {
    const url = `${ServerSettings.baseUrl}/units/${id}/controls/filter`;
    const response = await xhr.put({}, url);

    return response.data;
  }

  static async requestStatsRefresh(id) {
    const url = `${ServerSettings.baseUrl}/units/${id}/stats/basic/refresh`;
    const response = await xhr.post({}, url);

    return response.data;
  }

  static async setActiveSwingMode(id, swingMode) {
    const url = `${ServerSettings.baseUrl}/units/${id}/controls/swings`;
    const response = await xhr.put({ swingMode }, url);

    return response.data;
  }

  static async setActiveOperationStatus(id, operationStatus) {
    const url = `${ServerSettings.baseUrl}/units/${id}/controls/switches`;
    const response = await xhr.put({ operationStatus }, url);

    return response.data;
  }

  static async getBasicStats(id, startTime, endTime, granularity) {
    const url = `${ServerSettings.baseUrl}/units/${id}/stats/basic?startTime=${startTime}&endTime=${endTime}&granularity=${granularity}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getProSupportedServiceParams(id) {
    const url = `${ServerSettings.baseUrl}/units/${id}/stats/pro/supported`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getUnitStats(id, startTime, endTime, size) {
    const url = `${ServerSettings.baseUrl}/units/${id}/stats/basic/summary?startTimeUTC=${startTime}&endTimeUTC=${endTime}&bucketSizeMsec=${size}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getUnitStatsByParams(id, start, end, params) {
    const paramsStrArr = params.map((param: any) => `params[]=${param}`);
    const url = `${ServerSettings.baseUrl}/units/${id}/stats/basic/params?startTimeUTC=${start}&endTimeUTC=${end}&${paramsStrArr.join('&')}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getProExtendedServiceParams(data) {
    const params = _.reduce(
      data.params,
      (paramsString, typeCode) => paramsString + `&params[]=${typeCode}`,
      ""
    );
    const units = _.reduce(
      data.units,
      (unitsString, unit) => unitsString + `&units[]=${unit}`,
      ""
    );
    const url =
      `${ServerSettings.baseUrl}/units/stats/pro/extended?startTime=${data.startTime}&endTime=${data.endTime}${data.withQuality
        ? "&lineQuality=true"
        : ""}&granularity=minute&noreduce=${data.noreduce}` +
      params +
      units;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getProStats(
    id,
    startTime,
    endTime,
    granularity,
    withQuality,
    statTypeCodes
  ) {
    const params = _.reduce(
      statTypeCodes,
      (paramsString, typeCode) => paramsString + `&params[]=${typeCode}`,
      ""
    );
    const url =
      `${ServerSettings.baseUrl}/units/${id}/stats/pro/extended?startTime=${startTime}&endTime=${endTime}${withQuality
        ? "&lineQuality=true"
        : ""}&granularity=${granularity}` + params;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getUnits() {
    const url = `${ServerSettings.baseUrl}/units`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getUnitById(id) {
    const url = `${ServerSettings.baseUrl}/units/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  // Called by service unit.
  static async associateControlUnitToServiceUnit(id, controlUnitId) {
    const url = `${ServerSettings.baseUrl}/units/${id}/associate/${controlUnitId}`;
    const response = await xhr.put({}, url);

    return response.data;
  }

  // Called by service unit.
  static async dissociateControlUnitFromServiceUnit(id) {
    const url = `${ServerSettings.baseUrl}/units/${id}/associate`;
    const response = await xhr.delete(url);

    return response.data;
  }
  static async assigningServiceUnitToBranchBoxUnit(id, branchBoxUnitId) {
    const url = `${ServerSettings.baseUrl}/units/${id}/associate-branch-box/${branchBoxUnitId}`;
    const response = await xhr.put({}, url);

    return response.data;
  }
  static async unAssigningServiceUnitToBranchBoxUnit(id) {
    const url = `${ServerSettings.baseUrl}/units/${id}/associate-branch-box`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static convertTemperatureTo(temp, convertTo) {
    if (convertTo.toUpperCase() === "F") {
      temp = 32 + temp * 9 / 5;
    } else {
      temp = (temp - 32) * 5 / 9;
    }
    //needs to be changed to math.round()
    return Math.trunc(temp);
  }
  static async setParamValue(id, value, serviceParamCode) {
    const url = `${ServerSettings.baseUrl}/units/${id}/service-params/${serviceParamCode}`;
    const response = await xhr.post({ value }, url);

    return response.data;
  }

  static async associateControlUnitToOtherUnit(id, controlUnitId) {
    const url = `${ServerSettings.baseUrl}/units/${id}/associateOther/${controlUnitId}`;
    const response = await xhr.put({}, url);

    return response.data;
  }

  static async dissociateControlUnitFromOtherUnit(id) {
    const url = `${ServerSettings.baseUrl}/units/${id}/associateOther`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async getUnitParamsAndStats(unitId, startTime, endTime) {
    const url = `${ServerSettings.baseUrl}/service-params/units/${unitId}?startTimeUTC=${startTime}&endTimeUTC=${endTime}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getUnitCategories(id) {
    const url = `${ServerSettings.baseUrl}/configuration-params/categories/units/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getUnitCategoryParams(unitId, category) {
    const url = `${ServerSettings.baseUrl}/configuration-params/parameters/units/${unitId}?category=${category}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getUnitParamsValues(id, params) {
    let paramsArray = "";
    params.forEach((param: string, index: number) => {
      if (index === 0) {
        paramsArray = paramsArray + `params[]=${param}`
        return
      }
      paramsArray = paramsArray + `&params[]=${param}`
    })

    const url = `${ServerSettings.baseUrl}/configuration-params/parameters/values/units/${id}?${paramsArray}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async updateUnitParamsValues(id, data) {
    const url = `${ServerSettings.baseUrl}/configuration-params/parameters/values/units/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async getUnitCategoryParamsByParams(unitId, params) {
    let paramsArray = "";
    params.forEach((param: string, index: number) => {
      if (index === 0) {
        paramsArray = paramsArray + `params[]=${param}`
        return
      }
      paramsArray = paramsArray + `&params[]=${param}`
    })
    const url = `${ServerSettings.baseUrl}/configuration-params/parameters/units/${unitId}?${paramsArray}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getUnitParamsAndStats1(unitId, startTime, endTime, noParams = false, onlyParams = false, params) {
    const paramsStrArr = params?.map((param: any) => `params[]=${param}`);
    const url = `${ServerSettings.baseUrl}/service-params/units/${unitId}?startTimeUTC=${startTime}&endTimeUTC=${endTime}&noParams=${noParams}&&onlyParams=${onlyParams}&${params && paramsStrArr?.length ? paramsStrArr.join('&') : ""}`;
    const response = await xhr.get(url);

    return response.data;
  }
}
