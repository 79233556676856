import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";
import * as _ from "lodash";
var querystring = require("querystring");

export class Event {
  static async fetch(id) {
    const url = `${ServerSettings.baseUrl}/events/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/events/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }
  static async setAcknowledgedEvent(id, data) {
    const url = `${ServerSettings.baseUrl}/events/${id}/acknowledged`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/events/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async getEvents(data: any = {}) {
    const { startTime, endTime, status, type } = data;
    const hasTime = !!startTime && !!endTime;
    const hasStatus = !!status;
    let url = `${ServerSettings.baseUrl}/events?${hasTime
      ? `startTime=${startTime}&endTime=${endTime}`
      : ""}${hasTime && hasStatus ? "&" : ""}${hasStatus
      ? `&status=${status}`
      : ""}${type ? `&type=${type}` : ""}`;

    const response = await xhr.get(url);
    return response.data;
  }

  static async getEventsPaginated(data) {
    let url = new URL(`${ServerSettings.baseUrl}/events/paginated?`);
    url += querystring.stringify({ data: JSON.stringify(data) });
    const response = await xhr.get(url);
    return response.data;
  }
}
