import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";

export class PowerMeter {
    static async find() {
        const url = `${ServerSettings.baseUrl}/powerMeters`;
        const response = await xhr.get(url);

        return response.data;
    }

    static async getPowerMeters() {
        const url = `${ServerSettings.baseUrl}/powerMeters`;
        const response = await xhr.get(url);

        return response.data;
    }

    static async findOne(id) {
        const url = `${ServerSettings.baseUrl}/powerMeters/${id}`;
        const response = await xhr.get(url);

        return response.data;
    }

    static async updatePowerMeter(id, data) {
        const url = `${ServerSettings.baseUrl}/powerMeters/${id}`;
        const response = xhr.put(data, url);

        return response;
    }

    static async deltePowerMeter(id) {
        const url = `${ServerSettings.baseUrl}/powerMeters/${id}`;
        const response = await xhr.delete(url);

        return response.data;
    }
}
