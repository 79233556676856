import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";
import * as _ from "lodash";

export class Scheduler {
  static async fetch(id) {
    const url = `${ServerSettings.baseUrl}/schedules/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/schedules/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/schedules/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async getScheduleById(id) {
    const url = `${ServerSettings.baseUrl}/schedules/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getObjectScheduleById(objectType, Id) {
    if (objectType !== 'unit' && objectType !== 'group') {
      return;
    }
    const url = `${ServerSettings.baseUrl}/${objectType}s/${Id}/schedules`;
    const response = await xhr.get(url);
    return response.data;
  }

  static async createObjectSchedule(objectType, objectId, data) {
    if (objectType !== 'unit' && objectType !== 'group') {
      return;
    }
    const url = `${ServerSettings.baseUrl}/${objectType}s/${objectId}/schedules`;
    const response = await xhr.post(data, url);
    return response.data;
  }
}
