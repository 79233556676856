import { ServerSettings } from "../lib/ServerSettings";
// import q from 'querystring';
import { xhr } from "../lib/xhr";
import * as _ from "lodash";
var querystring = require("querystring");

export class Audit {
  static async getAudits(params: any = "") {
    let query = "";
    // if (params) query = q.stringify(params);

    if (params) {
      const { startTime, endTime } = params;
      query = querystring.stringify({
        startTime: JSON.stringify(startTime),
        endTime: JSON.stringify(endTime),
      });
    }

    let url = `${ServerSettings.baseUrl}/audits?${query}`;

    const response = await xhr.get(url);

    return response.data;
  }

  static async getAuditById(id) {
    return this.fetch(id);
  }

  static async fetch(id) {
    const url = `${ServerSettings.baseUrl}/audits/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/audits/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/audits/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }
}
