import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";
import * as _ from "lodash";

export class SubscriptionPlan {
  static async getSubscriptionPlan(id) {
    const url = `${ServerSettings.baseUrl}/subscription-plans/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/subscription-plans/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/subscription-plans/${id}`;
    const response = await xhr.delete(url);
    return response.data;
  }
  static async associateSubscriptionToSite(id, siteId) {
    const url = `${ServerSettings.baseUrl}/subscription-plans/${id}/associate/${siteId}`;
    const response = await xhr.put({}, url);

    return response.data;
  }
  static async dissociateSubscriptionFromSite(id) {
    const url = `${ServerSettings.baseUrl}/subscription-plans/${id}/associate`;
    const response = await xhr.delete(url);

    return response.data;
  }
}
