import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";

export class Trap {
  static async fetch(id) {
    const url = `${ServerSettings.baseUrl}/traps/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getTraps(type) {
    const url = `${ServerSettings.baseUrl}/traps/${type}`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async assignToTraps(trapId, data) {
    const url = `${ServerSettings.baseUrl}/traps/${trapId}/assignments`;
    const response = await xhr.put(data, url);
    return response.data;
  }

  static async createAdminTrap(data) {
    const url = `${ServerSettings.baseUrl}/admin/traps`;
    const response = await xhr.post(data, url);

    return response.data;
  }
  static async getAdminTraps() {
    const url = `${ServerSettings.baseUrl}/admin/traps`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async createTrap(data, customerId) {
    const url = `${ServerSettings.baseUrl}/customers/${customerId}/traps`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async update(data, trapId) {
    const url = `${ServerSettings.baseUrl}/traps/${trapId}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/traps/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async getTrapTemplates() {
    const url = `${ServerSettings.baseUrl}/trap-templates`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async updateTrapTemplate(data, id) {
    const url = `${ServerSettings.baseUrl}/trap-templates/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async deleteTrapTemplate(id) {
    const url = `${ServerSettings.baseUrl}/trap-templates/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }
}
