import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";

export class Invite {
  static async acceptInvite(inviteId, data) {
    const url = `${ServerSettings.baseUrl}/root/invites/${inviteId}`;
    const response = await xhr.post(data, url);

    return response.data;
  }
  static async createInvite(data) {
    const url = `${ServerSettings.baseUrl}/root/invites`;
    const response = await xhr.post(data, url);

    return response.data;
  }
  public static async getInviteInfo(inviteToken) {
    const url = `${ServerSettings.baseUrl}/root/invites/${inviteToken}`;
    const response = await xhr.get(url);
    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/invites/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async sendInviteEmail(inviteId: string, email: any) {
    const url = `${ServerSettings.baseUrl}/services/emails/invite/${inviteId}`;
    const response = await xhr.post(email, url);

    return response.data;
  }
}
