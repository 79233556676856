import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";
import * as _ from "lodash";

export class Group {
  async refresh(id) {
    const url = `${ServerSettings.baseUrl}/groups/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/groups/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/groups/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async addUnit(id, unitId) {
    const url = `${ServerSettings.baseUrl}/groups/${id}/units/${unitId}`;
    const response = await xhr.post(null, url);

    return response.data;
  }

  static async removeUnit(id, unitId) {
    const url = `${ServerSettings.baseUrl}/groups/${id}/units/${unitId}`;
    const response = await xhr.delete(url);

    return response.data;
  }
  static async addSensor(id, sensorId) {
    const url = `${ServerSettings.baseUrl}/groups/${id}/sensors/${sensorId}`;
    const response = await xhr.post(null, url);

    return response.data;
  }

  static async removeSensor(id, sensorId) {
    const url = `${ServerSettings.baseUrl}/groups/${id}/sensors/${sensorId}`;
    const response = await xhr.delete(url);

    return response.data;
  }
  static async getUnits(id) {
    const url = `${ServerSettings.baseUrl}/groups/${id}/units`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getGroups() {
    const url = `${ServerSettings.baseUrl}/groups`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getFullGroups() {
    const url = `${ServerSettings.baseUrl}/groups/full`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getGroupById(id) {
    const url = `${ServerSettings.baseUrl}/groups/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async setActiveOperationStatus(id, data) {
    const url = `${ServerSettings.baseUrl}/groups/${id}/controls/switches`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async setActiveSetpoint(id, setpoint: number) {
    const url = `${ServerSettings.baseUrl}/groups/${id}/controls/setpoints`;
    const response = await xhr.put({ setpoint }, url);

    return response.data;
  }
}
