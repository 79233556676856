import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";
import * as _ from "lodash";

export class File {


  static async getSiteFiles(id) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/user-files`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getUnitFiles(id) {
    const url = `${ServerSettings.baseUrl}/units/${id}/user-files`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async uploadFile(data) {
    const url = `${ServerSettings.baseUrl}/services/files/uploads`;
    const response = await xhr.sendFile(data, url);

    return response.data;
  }

  static async updateFilesInfo(id, data) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/user-files`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/user-files/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/user-files/${id}`;
    const response = await xhr.delete(url);
    return response.data;
  }

}
