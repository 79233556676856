import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";

export class Customer {
  static async fetch(id) {
    const url = `${ServerSettings.baseUrl}/customers/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/customers/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/customers/${id}`;
    const response = await xhr.delete(url);
    return response.data;
  }

  static async getCustomers(brand = -1) {
    const url = `${ServerSettings.baseUrl}/customers${brand > -1 ? `?brand=${brand}` : ""}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getCustomerById(id) {
    const url = `${ServerSettings.baseUrl}/customers/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async createCustomer(data) {
    const url = `${ServerSettings.baseUrl}/admin/customers`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async createSite(id, data) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/sites`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async createCustomerUsingDevice(data) {
    const url = `${ServerSettings.baseUrl}/root/customers`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async getSites(id, brand = -1) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/sites${brand > -1 ? `?brand=${brand}` : ""}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getUsers(id) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/users`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async createUser(id, data) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/users`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async getContacts() {
    const url = `${ServerSettings.baseUrl}/contacts`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async createContact(id, data) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/contacts`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async createInvite(id, data) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/invites`;
    const response = await xhr.post(data, url);

    return response.data;
  }
  static async getInvites(id) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/invites`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async createGroup(id, data) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/groups`;
    const response = await xhr.post(data, url);

    return response.data;
  }
  static async getGroups(id) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/groups`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async createUserUsingDevice(id, data) {
    const url = `${ServerSettings.baseUrl}/root/customers/${id}/users`;
    const response = await xhr.post(data, url);

    return response.data;
  }
  static async getAlertGroups(id) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/alertgroups`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async createAlertGroup(id, data) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/alertgroups`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async getOperationalAlertGroups(id) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/operational-alertgroups`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async createOperationalAlertGroup(id, data) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/operational-alertgroups`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async getTriggers(id) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/triggers`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async getTraps(id) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/traps`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async createTrigger(id, data) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/triggers`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async createTriggerFromTriggerTemplate(id, data) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/triggers/trigger-templates`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async createSchedule(id, data) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/schedules`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async getSchedules(data) {
    let url = "";
    if (typeof data === "string") {
      url = `${ServerSettings.baseUrl}/customers/${data}/schedules`;
    } else {
      url = `${ServerSettings.baseUrl}/customers/${data.id}/schedules?type=${data.type}`;
    }

    const response = await xhr.get(url);

    return response.data;
  }

  static async getScripts(id) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/procedures`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async createScript(id, data) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/procedures`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async getProcedureReportByCustomer(id, data: any = {}) {
    const { startTime, endTime } = data;
    const url = `${ServerSettings.baseUrl}/customers/${id}/procedure-reports?startTime=${startTime}&endTime=${endTime}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getProcedureInstancesByCustomer(id) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/procedure-instances`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getProceduresByCustomer(id) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/procedures`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async getSubscriptionPlans(id) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/subscription-plans`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async createSubscriptionPlan(id, data) {
    const url = `${ServerSettings.baseUrl}/customers/${id}/subscription-plans`;
    const response = await xhr.post(data, url);

    return response.data;
  }
}
