import { xhr } from "../lib/xhr";
import { ServerSettings } from "../lib/ServerSettings";
import * as _ from "lodash";

export class Policy {
  static async getPolicies() {
    const url = `${ServerSettings.baseUrl}/policies`;
    const response = await xhr.get(url);

    return response.data;
  }
}
