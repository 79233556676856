export enum Code {
  success = 1,
  created = 2,
  updated = 3,
  deleted = 4,
  exist = 5,
  changed = 6,
  emptyReq = 7,
  notFound = 8,
  forbidden = 8,
  fail = 10,
  error = 11,
  unauthorized = 12,
  badRequest = 13
}


export const Status = {
  empty: {
      status: Code.emptyReq,
      message: 'please pass all the attributes'
  },
  notFound: {
      status: Code.notFound,
      message: 'not found'
  },
  fail: {
      status: Code.fail,
      message: 'fail'
  },
  scs: {
      status: Code.success,
      message: 'success'
  },
  err: {
      status: Code.error,
      message: 'error'
  },
  updated: {
      status: Code.updated,
      message: 'updated'
  },
  created: {
      status: Code.created,
      message: 'created'
  },
  deleted: {
      status: Code.deleted,
      message: 'deleted'
  },
  forbidden: {
      status: Code.forbidden,
      message: 'forbidden'
  },
  exist: {
      status: Code.exist,
      message: 'already exist'
  },
  changed: {
      status: Code.changed,
      message: 'changed'
  },
  unauthorized: {
      status: Code.unauthorized,
      message: 'unauthorized'
  }
}

