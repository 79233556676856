import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";

export class Nest {
    static async find() {
        const url = `${ServerSettings.baseUrl}/nest`;
        const response = await xhr.get(url);

        return response.data;
    }

    static async findOne(id) {
        const url = `${ServerSettings.baseUrl}/nest/${id}`;
        const response = await xhr.get(url);

        return response.data;
    }

    static async connectNestAcount() {
        const url = `${ServerSettings.baseUrl}/nest/auth`;
        xhr.get(url);

        return true;
    }

    static async connectNestAcountURL() {
        const url = `${ServerSettings.baseUrl}/nest/authUrl`;
        const response = await xhr.get(url);

        return response.data;
    }


    static async isConnected() {
        const url = `${ServerSettings.baseUrl}/nest/isConnected`;
        const response = await xhr.get(url);

        return response.data;
    }

    static async disconnectNestAcount() {
        const url = `${ServerSettings.baseUrl}/nest/auth/disconnect`;
        const response = await xhr.delete(url);

        return response.data;
    }
}
