import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";

export class Sensor{
    static async getSiteSensors(id) {
        const url = `${ServerSettings.baseUrl}/sites/${id}/sensors`;
        const response = await xhr.get(url);
    
        return response.data;
    }
    static async getMySensors() {
        const url = `${ServerSettings.baseUrl}/sensors`;
        const response = await xhr.get(url);
    
        return response.data;
    }
    static async getSensorById(id) {
        const url = `${ServerSettings.baseUrl}/sensors/${id}`;
        const response = await xhr.get(url);
    
        return response.data;
    }
    static async getSensorsByDevice(id) {
        const url = `${ServerSettings.baseUrl}/devices/${id}/sensors`;
        const response = await xhr.get(url);
    
        return response.data;
    }

    static async update(id, data) {
        const url = `${ServerSettings.baseUrl}/sensors/${id}`;
        const response = await xhr.put(data, url);
    
        return response.data;
    }

    static async updateSensorValues(id, data) {
        const url = `${ServerSettings.baseUrl}/sensors/${id}/values`;
        const response = await xhr.put(data, url);
    
        return response.data;
    }
}