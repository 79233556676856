import { xhr } from "../lib/xhr";
import { ServerSettings } from "../lib/ServerSettings";

export class ServiceApplication {
  static async getFlags() {
    const url = `${ServerSettings.baseUrl}/applications/service/user-flags`;
    const response = await xhr.get(url);
    return response.data;
  }
}

export class ControlApplication {
  static async getFlags() {
    const url = `${ServerSettings.baseUrl}/applications/control/user-flags`;
    const response = await xhr.get(url);
    return response.data;
  }
}

export class ManagementApplication {
  static async getFlags() {
    const url = `${ServerSettings.baseUrl}/applications/management/user-flags`;
    const response = await xhr.get(url);
    return response.data;
  }
}

export class ConsoleApplication {
  static async getFlags() {
    const url = `${ServerSettings.baseUrl}/applications/console/user-flags`;
    const response = await xhr.get(url);
    return response.data;
  }
}
