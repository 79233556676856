import { xhr } from "../lib/xhr";
import { ServerSettings } from "../lib/ServerSettings";
import * as _ from "lodash";

export class Role {
  static async getRoles() {
    const url = `${ServerSettings.baseUrl}/roles`;
    const response = await xhr.get(url);

    return response.data;
  }
}
