import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";
import * as _ from "lodash";

export class PowerReportSchedule {
  static async fetch(id) {
    const url = `${ServerSettings.baseUrl}/power-report-schedules/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async getScheduledReport(reportId, data) {
    const url = `${ServerSettings.baseUrl}/power-report-schedules/${reportId}`;
    const response = await xhr.put(data, url);

    return response.data;
  }
  static async updateScheduledReport(reportId, data) {
    const url = `${ServerSettings.baseUrl}/power-report-schedules/${reportId}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async removeScheduledReport(reportId) {
    const url = `${ServerSettings.baseUrl}/power-report-schedules/${reportId}`;
    const response = await xhr.delete(url);

    return response.data;
  }
}
