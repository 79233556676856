import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";
import * as _ from "lodash";

export class Site {
  static async fetch(id) {
    const url = `${ServerSettings.baseUrl}/sites/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/sites/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/sites/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async createInvite(id, data) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/invites`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async createDevice(id, data) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/devices`;
    const response = await xhr.post(data, url);
    return response.data;
  }

  static async getDevices(id) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/devices`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async createZone(id, data) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/zones`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async createGroup(id, data) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/groups`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async createSchedule(id, data) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/schedules`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async getZones(id) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/zones`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getGroups(id) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/groups`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getUnits(id) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/units`;
    let response = await xhr.get(url);
    return response.data;
  }

  static async getSchedules(data) {
    let url = "";
    if (typeof data === "string") {
      url = `${ServerSettings.baseUrl}/sites/${data}/schedules`;
    } else {
      url = `${ServerSettings.baseUrl}/sites/${data.id}/schedules?type=${data.type}`;
    }
    const response = await xhr.get(url);

    return response.data;
  }

  static async getUsers(id) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/users`;
    const response = await xhr.get(url);
    return response.data;
  }
  static async getInvites(id) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/invites`;
    const response = await xhr.get(url);
    return response.data;
  }
  static async getSites(onlyIsBlocked = false) {
    const url = `${ServerSettings.baseUrl}/sites${onlyIsBlocked ? "?isBlocked=true" : ""}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getSiteById(id) {
    const url = `${ServerSettings.baseUrl}/sites/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async createUser(id, data) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/users`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async createNotification(id, data) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/notifications`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async createTrigger(id, data) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/triggers`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async changeSitePower(id, operationStatus) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/controls/switches`;
    const response = await xhr.put({ operationStatus }, url);

    return response.data;
  }

  static async getPowerUsage(id, data) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/power?startTime=${data.startTime}&endTime=${data.endTime}&resolution=${data.resolution}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async changeSiteSetPoints(id, setpoint) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/controls/setpoints`;
    const response = await xhr.put({ setpoint }, url);

    return response.data;
  }

  static async createProcedure(id, data) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/procedures`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async getProcedures(id) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/procedures`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getProceduresInstances(id) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/procedure-instances`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getProceduresReports(id, data: any = {}) {
    const { startTime, endTime } = data;
    const url = `${ServerSettings.baseUrl}/sites/${id}/procedure-reports?startTime=${startTime}&endTime=${endTime}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getSystems(id) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/systems`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async getScheduledReports(id) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/power-report-schedules`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async createScheduledReports(id, data) {
    const url = `${ServerSettings.baseUrl}/sites/${id}/power-report-schedules`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  // static async updateScheduledReport(systemId, reportId, data) {
  //   const url = `${ServerSettings.baseUrl}/sites/${systemId}/reports/${reportId}`;
  //   const response = await xhr.put(data, url);

  //   return response.data;
  // }

  // static async removeScheduledReport(id, reportId) {
  //   const url = `${ServerSettings.baseUrl}/sites/${id}/reports/${reportId}`;
  //   const response = await xhr.delete(url);

  //   return response.data;
  // }
}
