import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";

export class Ecobee {
  static async find() {
    const url = `${ServerSettings.baseUrl}/ecobee`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async findOne(id) {
    const url = `${ServerSettings.baseUrl}/ecobee/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async connectEcobeeAcount() {
    const url = `${ServerSettings.baseUrl}/ecobee/auth`;
    xhr.get(url);

    return true;
  }

  static async connectEcobeeAcountURL() {
    const url = `${ServerSettings.baseUrl}/ecobee/authUrl`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async isConnected() {
    const url = `${ServerSettings.baseUrl}/ecobee/isConnected`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async disconnectEcobeeAcount() {
    const url = `${ServerSettings.baseUrl}/ecobee/auth/disconnect`;
    const response = await xhr.delete(url);

    return response.data;
  }
}
