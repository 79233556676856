import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";

export class Notes {
    static async getUnitNotes(unitId) {
        const url = `${ServerSettings.baseUrl}/units/${unitId}/user-notes`;
        const response = await xhr.get(url);

        return response.data;
    }

    static async getSystemNotes(systemId) {
        const url = `${ServerSettings.baseUrl}/systems/${systemId}/user-notes`;
        const response = await xhr.get(url);

        return response.data;
    }

    static async delete(noteId) {
        const url = `${ServerSettings.baseUrl}/user-notes/${noteId}`;
        const response = await xhr.delete(url);

        return response.data;
    }

    static async createNote(type: 'unit' | 'system', id, data) {
        const url = `${ServerSettings.baseUrl}/${type === 'unit' ? 'units' : 'systems'}/${id}/user-notes`;
        const response = await xhr.post(data, url);

        return response.data;
    }


}
