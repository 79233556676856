
import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";

export class Compressor {
    static async getCompressors() {
        const url = `${ServerSettings.baseUrl}/compressors`;
        const response = await xhr.get(url);

        return response.data;
    }


}
