import { xhr } from "../lib/xhr";
import { ServerSettings } from "../lib/ServerSettings";
import * as _ from "lodash";

const wsReconnectTimeoutMsec = 30000;

export class User {
  static ws;
  static userWsDisconnect;

  static async fetch(id) {
    const url = `${ServerSettings.baseUrl}/users/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/users/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/users/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async updatePassword(id, oldPassword: string, newPassword: string) {
    const url = `${ServerSettings.baseUrl}/users/${id}/password`;
    const response = await xhr.put(
      { oldPassword: oldPassword, newPassword: newPassword },
      url
    );
    return response.data;
  }

  static async getMe() {
    const url = `${ServerSettings.baseUrl}/me`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getMyTree() {
    const url = `${ServerSettings.baseUrl}/me/tree`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getUsers() {
    const url = `${ServerSettings.baseUrl}/users`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getUserById(id) {
    const url = `${ServerSettings.baseUrl}/users/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async createUser(data) {
    const url = `${ServerSettings.baseUrl}/users`;
    const response = await xhr.post(data, url);

    return response.data;
  }
  static async getRecoveryToken(data, type, app?) {
    const url = `${ServerSettings.baseUrl}/root/forgot-password`;
    const payload =
      type === "username"
        ? {
            username: data,
            app,
          }
        : {
            email: data,
            app,
          };
    let response = await xhr.post(payload, url);

    return response.data;
  }

  static async resetPassword(token, password) {
    const url = `${ServerSettings.baseUrl}/root/reset-password`;
    const payload = { token, password };
    let response = await xhr.post(payload, url);

    return response;
  }

  static async connect(username, password, appId = "") {
    if (User.isConnected()) {
      return;
    }

    const url = `${ServerSettings.baseUrl}/authenticate`;
    const payload: any = {
      username: username,
      password: password,
    };
    if (appId) payload.appId = appId;
    let response = await xhr.post(payload, url);
    return response;
  }

  public static async isUsernameAvailable(username: string) {
    const url = `${ServerSettings.baseUrl}/services/users/usernames/${username}`;
    const response = await xhr.get(url);

    return response.data.exists;
  }

  static async openWebSocket(callback) {
    const authenticate = async token => {
      User.ws.send(
        JSON.stringify({
          type: "authenticate",
          content: {
            token,
          },
        })
      );
    };

    const connectWebSocket = () => {
      console.log("websocket connecting");
      //indicate the user no longer specifically asked to disconnect
      User.userWsDisconnect = false;

      let timeoutHandle;
      let token = xhr.getToken();
      if (!token) {
        // console.error('no token when trying to connect to websocket');
        return;
      }
      User.ws = new WebSocket(ServerSettings.wsUrl);
      
      //set a timeout to reconnect if not being reset after 30 seconds
      timeoutHandle = setTimeout(connectWebSocket, wsReconnectTimeoutMsec);

      //authenticate when opened
      User.ws.addEventListener("open", async () => {
        console.log("websocket open");
        await authenticate(token);
      });

      //renew when closed
      User.ws.addEventListener("close", async event => {
        console.log("websocket closed status:", event.currentTarget.readyState);
        //remove the re-connect timeout handle only if user specifically asked to disconnect
        if (User.userWsDisconnect === true) {
          clearTimeout(timeoutHandle);
          console.log('websocket user asked to disconnect');
        }
      });

      // add listneres
      User.ws.addEventListener("message", async function(data) {
        //console.log(data, "websocket recieved data");
        const message = JSON.parse(data.data);
        //any type of data resets the re-connect timeout
        clearTimeout(timeoutHandle);
        timeoutHandle = setTimeout(connectWebSocket, wsReconnectTimeoutMsec);
        //if ping, send pong
        if (message.type === "ping") {
          User.ws.send(JSON.stringify({type: "pong"}));
        }
        // Emit messages
        if (callback) await callback(message);
      });
    };

    connectWebSocket();
  }

  public static closeWebSocket() {
    if (!User.ws) {
      return;
    }
    //indicate that the user asked to disconnect
    User.userWsDisconnect = true;
    return User.ws.close();
  }

  public static isConnected() {
    const isConnected = xhr.getToken();
    return !!isConnected;
  }

  public static logout() {
    xhr.setToken("");
  }

  static async reportProblem(data) {
    const url = `${ServerSettings.baseUrl}/services/support/ticket`;
    const response = await xhr.post(data, url);
    return response.data;
  }
  
  static async getUserPreferences() {
    const url =`${ServerSettings.baseUrl}/me/preferences`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async updateUserPreferences(data) {
    const url =`${ServerSettings.baseUrl}/me/preferences`;
    const response = await xhr.put(data, url);

    return response.data;
  }
}
