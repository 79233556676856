import { Code } from "./config";
let token: string;
let handleTimeout: any;

const events = {
  "token-expired": [],
};

const statusToCode = {
  400: Code.badRequest,
  401: Code.unauthorized,
  403: Code.forbidden,
  404: Code.notFound,
  409: Code.exist,
  500: Code.fail,
};

function throwError(status) {
  if (status === 401) {
    for (let i = 0; i < events["token-expired"].length; i++) {
      events["token-expired"][i]();
    }
    return xhr.setToken("");
  }

  return (obj) => {
    throw {
      message: obj.message,
      code: statusToCode[status],
      status: status,
    };
  };
}

function returnSuccess(obj) {
  return {
    data: obj.data,
  };
}

function handleResponse(res) {
  if (res.status === 200) {
    return res.json().then(returnSuccess);
  } else {
    return res.json().then(throwError(res.status));
  }
}

function fetchWrapper(method, url, attr) {
  return fetch(url, {
    method: method,
    headers: new Headers({
      "Content-type": "application/json; charset=UTF-8",
      "x-access-token": token,
    }),
    body: attr ? JSON.stringify(attr) : undefined,
  }).then(handleResponse);
}

export const xhr = {
  setToken(newToken: string) {
    token = newToken;
  },

  getToken() {
    return token;
  },

  get(url) {
    return fetchWrapper("get", url, undefined);
  },

  post(attr, url) {
    return fetchWrapper("post", url, attr);
  },

  put(attr, url) {
    return fetchWrapper("put", url, attr);
  },

  delete(url) {
    return fetchWrapper("delete", url, undefined);
  },

  on(type: string, fn: () => void) {
    events[type].push(fn);
  },

  sendFile(attr, url) {
    return fetch(url, {
      method: 'POST',
      headers: new Headers({
        "x-access-token": token,
      }),
      body: attr,
    }).then(handleResponse);
  },
};
