import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";

export class Contact{

    static async getContact(id) {
        const url = `${ServerSettings.baseUrl}/contacts/${id}`;
        const response = await xhr.get(url);
    
        return response.data;
    }
  
    static async updateContact(id, data) {
        const url = `${ServerSettings.baseUrl}/contacts/${id}`;
        const response = await xhr.put(data, url);
    
        return response.data;
    }
  
    static async deleteContact(id) {
        const url = `${ServerSettings.baseUrl}/contacts/${id}`;
        const response = await xhr.delete(url);
    
        return response.data;
    }

}