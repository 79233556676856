import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";
import { Unit } from "./unit";
import * as _ from "lodash";

export class Zone {
  static async fetch(id) {
    const url = `${ServerSettings.baseUrl}/zones/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/zones/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/zones/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async addUnit(id, unitId) {
    const url = `${ServerSettings.baseUrl}/zones/${id}/units/${unitId}`;
    const response = await xhr.post(null, url);

    return response.data;
  }

  static async removeUnit(id, unitId) {
    const url = `${ServerSettings.baseUrl}/zones/${id}/units/${unitId}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async getUnits(id) {
    const url = `${ServerSettings.baseUrl}/zones/${id}/units`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getZones() {
    const url = `${ServerSettings.baseUrl}/zones`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getZoneById(id) {
    const url = `${ServerSettings.baseUrl}/zones/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }
}
