import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";
import * as _ from "lodash";

export class System {
  static async fetch(id) {
    const url = `${ServerSettings.baseUrl}/systems/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/systems/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/systems/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async addUnit(id, unitId) {
    const url = `${ServerSettings.baseUrl}/systems/${id}/units/${unitId}`;
    const response = await xhr.post(null, url);

    return response.data;
  }

  static async removeUnit(id, unitId) {
    const url = `${ServerSettings.baseUrl}/systems/${id}/units/${unitId}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async getUnits(id) {
    const url = `${ServerSettings.baseUrl}/systems/${id}/units`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async assignPowerMeter(systemId, powerMeterId) {
    const url = `${ServerSettings.baseUrl}/systems/${systemId}/powerMeter/${powerMeterId}`;
    const response = await xhr.post(null, url);

    return response.data;
  }

  static async unAssignPowerMeter(systemId, powerMeterId) {
    const url = `${ServerSettings.baseUrl}/systems/${systemId}/powerMeter/${powerMeterId}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async getSystems() {
    const url = `${ServerSettings.baseUrl}/systems`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getSystemById(id) {
    const url = `${ServerSettings.baseUrl}/systems/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async changeSystemPower(id, data) {
    const url = `${ServerSettings.baseUrl}/systems/${id}/controls/switches`;
    const response = await xhr.put({ operationStatus: data }, url);

    return response.data;
  }
  static async changeSystemOperationMode(id, data) {
    const url = `${ServerSettings.baseUrl}/systems/${id}/controls/modes`;
    const response = await xhr.put({ operationMode: data }, url);

    return response.data;
  }

  static async getSystemProData(id, data) {
    const url = `${ServerSettings.baseUrl}/systems/${id}/service-stats?startTime=${data.startTime}&endTime=${data.endTime}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getSystemDiagData(id, startTime, endTime) {
    const url = `${ServerSettings.baseUrl}/service-params/systems/${id}?startTimeUTC=${startTime}&endTimeUTC=${endTime}`;
    const response = await xhr.get(url);

    return response.data;
  }
}
