import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";
import * as _ from "lodash";

export class TriggerTemplate {
  static async fetch(id) {
    const url = `${ServerSettings.baseUrl}/trigger-templates/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/trigger-templates/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/trigger-templates/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async getTriggersTemplates() {
    const url = `${ServerSettings.baseUrl}/trigger-templates`;
    const response = await xhr.get(url);

    return response.data;
  }
}
