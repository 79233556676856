import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";

export class Script {
  static async fetch(id) {
    const url = `${ServerSettings.baseUrl}/procedures/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/procedures/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/procedures/${id}`;
    const response = await xhr.delete(url);
    return response.data;
  }

  static async updateScriptState(id, data) {
    const url = `${ServerSettings.baseUrl}/procedures/${id}/state`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async getCommissioningReports() {
    const url = `${ServerSettings.baseUrl}/procedure-reports`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async deleteCommissioningReport(id) {
    const url = `${ServerSettings.baseUrl}/procedure-reports/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }

    static async runProcedure(id, data) {
    const url = `${ServerSettings.baseUrl}/procedures/${id}/run`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async stopProcedureInstance(id, data = {}) {
    const url = `${ServerSettings.baseUrl}/procedure-instances/${id}/stop`;
    const response = await xhr.post(data, url);

    return response.data;
  }

    static async getProcedureInstance(id) {
      const url = `${ServerSettings.baseUrl}/procedure-instances/${id}`;
      const response = await xhr.get(url);

    return response.data;
  }

  static async reRunProcedure(id) {
    const url = `${ServerSettings.baseUrl}/procedure-reports/${id}/run`;
    const response = await xhr.post(null, url);

    return response.data;
  }
}
