import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";
import * as _ from "lodash";

export class AlertGroup {
  static async fetch(id) {
    const url = `${ServerSettings.baseUrl}/alertgroups/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/alertgroups/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/alertgroups/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async getContacts(id) {
    const url = `${ServerSettings.baseUrl}/alertgroups/${id}/contacts`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async addContact(id, contactId, data) {
    const url = `${ServerSettings.baseUrl}/alertgroups/${id}/contacts/${contactId}`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async removeContact(id, contactId) {
    const url = `${ServerSettings.baseUrl}/alertgroups/${id}/contacts/${contactId}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async updateContact(id, contactId, data) {
    const url = `${ServerSettings.baseUrl}/alertgroups/${id}/contacts/${contactId}`;
    const response = await xhr.put(data, url);
    return response.data;
  }

  static async getAlertGroups() {
    const url = `${ServerSettings.baseUrl}/alertgroups`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async fetchOperational(id) {
    const url = `${ServerSettings.baseUrl}/operational-alertgroups/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async updateOperational(id, data) {
    const url = `${ServerSettings.baseUrl}/operational-alertgroups/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async addUser(id, userId, data) {
    const url = `${ServerSettings.baseUrl}/alertgroups/${id}/users/${userId}`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async removeUser(id, userId) {
    const url = `${ServerSettings.baseUrl}/alertgroups/${id}/users/${userId}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async updateUser(id, userId, data) {
    const url = `${ServerSettings.baseUrl}/alertgroups/${id}/users/${userId}`;
    const response = await xhr.put(data, url);
    return response.data;
  }

  static async deleteOperational(id) {
    const url = `${ServerSettings.baseUrl}/operational-alertgroups/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }
  static async getOperationalAlertGroups() {
    const url = `${ServerSettings.baseUrl}/operational-alertgroups`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getOperationalContacts(id) {
    const url = `${ServerSettings.baseUrl}/operational-alertgroups/${id}/contacts`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async addOperationalContact(id, contactId, data) {
    const url = `${ServerSettings.baseUrl}/operational-alertgroups/${id}/contacts/${contactId}`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async removeOperationalContact(id, contactId) {
    const url = `${ServerSettings.baseUrl}/operational-alertgroups/${id}/contacts/${contactId}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async updateOperationalContact(id, contactId, data) {
    const url = `${ServerSettings.baseUrl}/operational-alertgroups/${id}/contacts/${contactId}`;
    const response = await xhr.put(data, url);
    return response.data;
  }
}
