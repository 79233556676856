import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";
import * as _ from "lodash";
var querystring = require("querystring");

export class Device {
  static async fetch(id) {
    const url = `${ServerSettings.baseUrl}/devices/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async fetchTree(id) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/tree`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async refresh(id, data) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/refresh`;
    const response = await xhr.post(data, url);

    return response.data;
  }
  static async update(id, data) {
    const url = `${ServerSettings.baseUrl}/devices/${id}`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async delete(id) {
    const url = `${ServerSettings.baseUrl}/devices/${id}`;
    const response = await xhr.delete(url);

    return response.data;
  }

  static async getUnits(id) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/units`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getDevices() {
    const url = `${ServerSettings.baseUrl}/devices`;
    const response = await xhr.get(url);

    return response.data;
  }
  
  static async getDeviceLines(id) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/lines`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getLineQuality(id, lineId, params) {
    let query = "";
    // if (params) query = q.stringify(params);

    if (params) {
      const { startTime, endTime } = params;
      query = querystring.stringify({
        startTime: JSON.stringify(startTime),
        endTime: JSON.stringify(endTime),
      });
    }
    let url = `${ServerSettings.baseUrl}/devices/${id}/lines/${lineId}/stats/quality?${query}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async mapLineIndoors(id, lineId) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/lines/${lineId}/mapping`;
    const response = await xhr.post({}, url);

    return response.data;
  }

  static async getDeviceDaikinAllIndoorMapping(id) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/daikin/indoor`;
    const response = await xhr.get(url);

    return response.data;
  }


  static async getDeviceDaikinIndoorMapping(id, lineId) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/lines/${lineId}/daikin/indoor`;
    const response = await xhr.get(url);

    return response.data;
  }


  static async getDeviceDaikinOutdoorMapping(id, lineId) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/lines/${lineId}/daikin/outdoor`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getDeviceById(id) {
    const url = `${ServerSettings.baseUrl}/devices/${id}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getDeviceToken(data) {
    const { serial, pin } = data;
    const url = `${ServerSettings.baseUrl}/root/devices/registrations/tokens?serial=${serial}&pin=${pin}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async createPowerMeter(id, data) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/powerMeter`;
    const response = await xhr.post(data, url);

    return response.data;
  }
  static async mapLines(id, data) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/lines/mapping`;
    const response = await xhr.post(data, url);

    return response.data;
  }


  static async getPowerMeters(id) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/powerMeter`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async createSystem(id, data) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/systems`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async getSystems(id) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/systems`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async refreshSystems(id) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/systems`;
    const response = await xhr.put({}, url);

    return response.data;
  }

  static async controlDevice(id, data) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/control`;
    const response = await xhr.put(data, url);

    return response.data;
  }

  static async upgradeFirmware(id, data) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/firmwareUpgrade`;
    const response = await xhr.sendFile(data, url);

    return response.data;
  }

  static async firmwareStatus(id) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/firmwareStatus`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async createOtherUnit(id, data) {
    const url = `${ServerSettings.baseUrl}/devices/${id}/units`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async getEWrcLocks(deviceId) {
    const url = `${ServerSettings.baseUrl}/devices/${deviceId}/tStat`;
    const response = await xhr.get(url);

    return response.data;
  }
}
