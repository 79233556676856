import { ServerSettings } from "../lib/ServerSettings";
import { xhr } from "../lib/xhr";

export class Admin {
  static async createTrapTemplate(data) {
    const url = `${ServerSettings.baseUrl}/admin/trapTemplates`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async createTriggerTemplate(data) {
    const url = `${ServerSettings.baseUrl}/admin/trigger-templates`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async getTriggerTemplates() {
    const url = `${ServerSettings.baseUrl}/admin/trigger-templates`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async createCustomer(data) {
    const url = `${ServerSettings.baseUrl}/admin/customers`;
    const response = await xhr.post(data, url);

    return response.data;
  }

  static async getUserToken(data, appId) {
    const queryString = `?username=${data}&appId=${appId}`;
    const url = `${ServerSettings.baseUrl}/admin/user-token${queryString}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async deviceBySerial(data) {
    const queryString = `?serial=${data}`;
    const url = `${ServerSettings.baseUrl}/admin/deviceBySerial${queryString}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async usersByDevice(data) {
    const queryString = `?serial=${data}`;
    const url = `${ServerSettings.baseUrl}/admin/usersByDevice${queryString}`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async deviceByUser(data) {
    const queryString = `?username=${data}`;
    const url = `${ServerSettings.baseUrl}/admin/deviceByUser${queryString}`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async getGeneralStats(data?) {
    const queryString = data ? `?customerId=${data}` : "";
    const url = `${ServerSettings.baseUrl}/admin/stats${queryString}`;
    const response = await xhr.get(url);
    return response.data;
  }
  static async getDevicesTableData() {
    const url = `${ServerSettings.baseUrl}/admin/stats/devices`;
    const response = await xhr.get(url);
    return response.data;
  }

  static async getLiteTree() {
    const url = `${ServerSettings.baseUrl}/admin/tree/lite`;
    const response = await xhr.get(url);
    return response.data;
  }
}
