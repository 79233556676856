import { xhr } from "../lib/xhr";
import { ServerSettings } from "../lib/ServerSettings";
import * as _ from "lodash";

export class Services {
  static async getTypes() {
    const url = `${ServerSettings.baseUrl}/services/types`;
    const response = await xhr.get(url);

    return response.data;
  }

  static async getServiceParamTypes() {
    const url = `${ServerSettings.baseUrl}/services/service-param-types`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async getServiceErrorTypes() {
    const url = `${ServerSettings.baseUrl}/services/error-codes`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async getRoles() {
    const url = `${ServerSettings.baseUrl}/services/roles`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async sendInviteEmail(attr, inviteId) {
    const url = `${ServerSettings.baseUrl}/services/emails/invite/${inviteId}`;
    const response = await xhr.post(attr, url);

    return response.data;
  }

  static async sendEventEmail(attr, eventId) {
    const url = `${ServerSettings.baseUrl}/services/emails/events/${eventId}`;
    const response = await xhr.post(attr, url);

    return response.data;
  }
  static async checkUsername() {
    const url = `${ServerSettings.baseUrl}/services/users/usernames/:username`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async getFeatures() {
    const url = `${ServerSettings.baseUrl}/services/features`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async getServiceParams(brand?, unitType?) {
    const url = `${ServerSettings.baseUrl}/services/serviceparams`;
    const params =
      !_.isNil(brand) && !_.isNil(unitType)
        ? `?brand=${brand}&unitType=${unitType}`
        : "";
    const response = await xhr.get(url + params);

    return response.data;
  }
  static async getTriggerTemplates() {
    const url = `${ServerSettings.baseUrl}/services/trigger-templates`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async generateTroubleshootReport(flow, unitId) {
    const url = `${ServerSettings.baseUrl}/services/anomaly-flows/${flow}/units/${unitId}`;
    const response = await xhr.get(url);

    return response.data;
  }
  static async getSubscriptionTemplates() {
    const url = `${ServerSettings.baseUrl}/services/subscription-templates`;
    const response = await xhr.get(url);

    return response.data;
  }
}
